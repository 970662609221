import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Navbar } from "@/widgets/layout";
import routes from "@/routes";

function App() {
  const visibleRoutes = routes.filter((route) => !route.hidden);

  return (
    <BrowserRouter>
      <ThemeProvider>
        <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
          <Navbar routes={visibleRoutes} />
        </div>
        <Suspense>
          <Routes>
            {routes.map(
              ({ path, element }) =>
                element && (
                  <Route key={path} exact path={path} element={element} />
                )
            )}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
