import React, { lazy } from "react";

const Home = lazy(() => import("@/pages/Home"));
const Products = lazy(() => import("@/pages/Products"));
const Showcase = lazy(() => import("@/pages/Showcase"));
const Aboutus = lazy(() => import("@/pages/Aboutus"));
const NotFoundPage = lazy(() => import("@/pages/404"));

export const routes = [
  {
    name: "home",
    path: "/",
    element: <Home />,
  },
  {
    name: "Products",
    path: "/products",
    element: <Products />,
  },
  {
    name: "Showcase",
    path: "/Showcase",
    element: <Showcase />,
  },
  {
    name: "About us",
    path: "/Aboutus",
    element: <Aboutus />,
  },
  {
    name: "Not Found",
    path: "*",
    element: <NotFoundPage />,
    hidden: true,
  },
];

export default routes;
